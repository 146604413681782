import React, { useCallback, useEffect } from 'react';
import DashboardCard from "components/DashboardCard";
import { Add, Pets } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { IntegratedPaging, PagingState } from "@devexpress/dx-react-grid";
import { Grid, PagingPanel, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import PatientChartsTableRow from "./PatientChartsTableRow";
import { actions } from '../PatientView.redux';
import { Button } from '@mui/material';
import PatientChartsCell from 'pages/PatientView/PatientCharts/PatientChartsCell';



const columns = [
	{ name: 'date_created', title: 'Date' },
	{ name: 'patient_name', title: 'Name' },
	{ name: 'patient_weight', title: 'Weight' },
	{ name: 'is_neutered', title: 'Neutered' },
	{ name: 'created_by', title: 'Created by' },
	{ name: 'actions', title: 'Actions' },
];


const PatientCharts = () => {
	const dispatch       = useDispatch(),
		  { patient_id } = useParams(),
		  hasLoaded      = useSelector(state => state.patientView.chartsPreviouslyLoaded),
		  loading        = useSelector(state => state.patientView.chartsLoading),
		  charts         = useSelector(state => state.patientView.charts),
		  loadCharts     = (patientID) => dispatch(actions.loadCharts(patientID));


	const handleAddNew = useCallback(() => {
		dispatch(actions.addNewChart(patient_id));
	}, [ dispatch, patient_id ]);


	useEffect(() => {
		if(!hasLoaded && !loading) {
			loadCharts(patient_id);
		}

	}, [ loadCharts, patient_id, hasLoaded, loading ]);


	return (
		<DashboardCard
			color={'deepPurple'}
			icon={<Pets />}
			topContent={"Charts"}
			topLabel={(
				<div>
					<div>Click a chart to edit, or</div>
					<div>
						<Button
							variant="contained"
							color="primary"
							size="small"
							startIcon={<Add />}
							onClick={handleAddNew}
							disabled={loading}
						>
							Add New
						</Button>
					</div>
				</div>
			)}
			size="xl"
		>
			<Grid
				rows={charts}
				columns={columns}
			>
				<PagingState
					defaultCurrentPage={0}
					defaultPageSize={10}
				/>
				<IntegratedPaging />
				<Table
					rowComponent={PatientChartsTableRow}
					cellComponent={PatientChartsCell}
				/>
				<TableHeaderRow />
				<PagingPanel pageSizes={[ 5, 10, 20, 50 ]} />
			</Grid>
		</DashboardCard>
	);
};


export default PatientCharts;
