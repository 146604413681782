import { useCallback, useMemo, useState } from 'react';
import api from 'api';
import { enqueueSnackbar } from 'notistack';



function getConfig({ objectType, objectID }) {
	switch(objectType) {
		case 'REPORT':
			return {
				ENDPOINT: `/reports/${objectID}`,
				CONFIRMATION: 'Are you sure you want to delete this report? This cannot be undone.',
				SUCCESS: 'Report deleted.',
				ERROR: 'There was an error deleting the requested report.',
			};
		case 'CHART':
			return {
				ENDPOINT: `/charts/${objectID}`,
				CONFIRMATION: 'Are you sure you want to delete this chart? This will also remove all associated reports, and cannot be undone.',
				SUCCESS: 'Chart deleted.',
				ERROR: 'There was an error deleting the requested chart.',
			};
		case 'PATIENT':
			return {
				ENDPOINT: `/patients/${objectID}`,
				CONFIRMATION: 'Are you sure you want to delete this patient? This will also remove all associated charts and reports, and cannot be undone.',
				SUCCESS: 'Patient deleted.',
				ERROR: 'There was an error deleting the requested patient.',
			};
		case 'CLIENT':
			return {
				ENDPOINT: `/clients/${objectID}`,
				CONFIRMATION: 'Are you sure you want to delete this client? This will also remove all associated patients, charts, and reports, and cannot be undone.',
				SUCCESS: 'Client deleted.',
				ERROR: 'There was an error deleting the requested client.',
			};
		default:
			return null;
	}
}

export const useDeleteObject = ({ objectType, objectID }) => {
	const [ loading, setLoading ] = useState(false);

	const deleteHandler = useCallback(async () => {
		const {
				  ENDPOINT,
				  SUCCESS,
				  ERROR,
			  } = getConfig({ objectType, objectID });

		setLoading(true);

		try {
			const result = await api.delete(ENDPOINT);

			setLoading(false);
			enqueueSnackbar(SUCCESS, { variant: 'success' });

			return result;
		} catch(err) {
			setLoading(false);
			enqueueSnackbar(ERROR, { variant: 'error' });

			return null;
		}
	}, [ objectType, objectID, setLoading ]);


	const confirmationMessage = useMemo(() => {
		const {
				  CONFIRMATION,
			  } = getConfig({ objectType, objectID });

		return CONFIRMATION;

	}, [ objectType, objectID ]);


	return useMemo(() => {
		return { confirmationMessage, deleteHandler, loading };
	}, [ confirmationMessage, deleteHandler, loading ]);
};


