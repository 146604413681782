import React, { useCallback, useRef, useState } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { useDispatch } from "react-redux";
import { Button, Menu, MenuItem } from '@mui/material';
import { actions } from './Patients.redux';
import { push } from 'connected-react-router';
import DeletePopover from 'components/DeletePopover';



const PatientCell = (props) => {
	const { column, row } = props;
	const id                              = row.patient_id,
		  dispatch                        = useDispatch(),
		  actionAnchor                    = useRef(null),
		  [ actionsOpen, setActionsOpen ] = useState(false);


	const beforeDeletePatient = useCallback(() => {
		dispatch(actions.setState({
			loading: true,
		}));
	}, [ dispatch ]);

	const afterDeletePatient = useCallback(() => {
		setActionsOpen(false);
		dispatch(actions.search());
	}, [ dispatch ]);


	const handleNavigate = useCallback(() => {
		dispatch(push(`/patients/view/${id}`));
	}, [ dispatch, id ]);



	if(column.name === 'actions') {
		return (
			<Table.Cell
				{...props}
			>
				<Button
					variant="outlined"
					size="large"
					onClick={() => setActionsOpen(true)}
					ref={actionAnchor}
				>
					...
				</Button>
				<Menu

					open={actionsOpen}
					onClose={() => setActionsOpen(false)}
					anchorEl={actionAnchor.current}
				>
					<MenuItem>
						<DeletePopover
							objectType="PATIENT"
							objectID={id}
							afterDelete={afterDeletePatient}
							beforeDelete={beforeDeletePatient}
						>
							<Button variant="text" size="large" color="error">
								Delete Patient
							</Button>
						</DeletePopover>
					</MenuItem>
				</Menu>

			</Table.Cell>
		);
	}

	return (
		<Table.Cell
			{...props}
			style={{ cursor: 'pointer' }}
			onClick={handleNavigate}
		/>
	);
};


export default PatientCell;
