import React, { useEffect } from 'react';
import { IntegratedPaging, PagingState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import PatientSearch from './PatientSearch';
import { useDispatch, useSelector } from "react-redux";
import { actions } from './Patients.redux';
import DashboardCard from "components/DashboardCard";
import { Pets } from "@mui/icons-material";
import { Grid as MUIGrid } from '@mui/material';
import PatientCell from './PatientCell';



const columns = [
	{ name: 'patient_name', title: 'Patient Name' },
	{ name: 'client_name', title: 'Client Name' },
	{ name: 'breed', title: 'Breed' },
	{ name: 'last_visit', title: 'Last Visit' },
	{ name: 'actions', title: 'Actions' },
];


const Patients = () => {

	const patientList  = useSelector(state => state.patients.patients),
		  dispatch     = useDispatch(),
		  handleSearch = () => dispatch(actions.search()),
		  resetAll     = () => dispatch(actions.resetAll());

	useEffect(() => {
		handleSearch();

		return () => {
			resetAll();
		};
	}, []);


	return (
		<MUIGrid container spacing={4}>
			<MUIGrid item xs={12} lg={3} xl={4}>
				<PatientSearch />
			</MUIGrid>

			<MUIGrid item xs={12} lg={9} xl={8}>
				<DashboardCard
					color={'green'}
					icon={<Pets />}
					topContent={"Patients"}
					topLabel="Click a patient to view their profile"
					size="xl"
				>

					<Grid
						rows={patientList}
						columns={columns}
					>
						<PagingState
							defaultCurrentPage={0}
							defaultPageSize={10}
						/>
						<IntegratedPaging />
						<Table cellComponent={PatientCell} />
						<TableHeaderRow />
						<PagingPanel pageSizes={[ 5, 10, 20, 50 ]} />
					</Grid>
				</DashboardCard>
			</MUIGrid>

		</MUIGrid>
	);

};



export default Patients;
