import React, { useCallback, useRef, useState } from 'react';
import { useDeleteObject } from './DeletePopover.handlers';
import { Box, Button, Popover } from '@mui/material';



const DeletePopover = ({
	children,
	objectType,
	objectID,
	beforeDelete,
	afterDelete,
	boxComponent = "div",
}) => {
	const boxRef = useRef(null);
	const [ showPopover, setShowPopover ] = useState(false);
	const { deleteHandler, confirmationMessage, loading } = useDeleteObject({ objectType, objectID });

	const handleDeleteWithUserFunctions = useCallback(async () => {
		if(beforeDelete) {
			beforeDelete();
		}

		await deleteHandler();
		setShowPopover(false);

		if(afterDelete) {
			afterDelete();
		}
	}, [ deleteHandler, beforeDelete, afterDelete ]);

	return (
		<Box
			component={boxComponent} ref={boxRef} onClick={() =>
			showPopover
				? null
				: setShowPopover(true)
		}
		>
			<Popover
				open={showPopover}
				anchorEl={boxRef.current}
				onClose={() => !loading && setShowPopover(false)}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
			>
				<Box sx={{ padding: 2, maxWidth: '300px' }}>
					{confirmationMessage}
				</Box>
				<Box sx={{ padding: 2 }}>
					<Button
						color="error"
						variant="contained"
						onClick={handleDeleteWithUserFunctions}
						disabled={loading}
					>
						Delete
					</Button>

					<Button
						variant="outlined"
						sx={{ marginLeft: 2 }}
						onClick={() => setShowPopover(false)}
						disabled={loading}
					>
						Cancel
					</Button>
				</Box>
			</Popover>
			{children}
		</Box>
	);
};


export default DeletePopover;
