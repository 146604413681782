import api from 'api';
import stripScriptTags from 'lib/stripScriptTags';



const baseType = 'REPORTS';

export const types = {
	SET_STATE: `${baseType}/SET_STATE`,
	SET_SUBSTATE: `${baseType}/SET_SUBSTATE`,
	RESET_ALL: `${baseType}/RESET_ALL`,
	RESET_PROPERTY: `${baseType}/RESET_PROPERTY`,
	UPDATE_REPORT_CONTENT: `${baseType}/UPDATE_REPORT_CONTENT`,
	SET_SEARCH_TERM: `${baseType}/SET_SEARCH_TERM`,
};

const initialState = {
	loading: false,

	reports: {
		rows: [],
		totalRows: 0,
		lastQuery: '',
		pageSize: 10,
		pageNumber: 0,
	},
	reportSearch: {
		report_name: '',
		patient_name: '',
		client_name: '',
		date_from: '',
		date_to: '',
	},

	templates: {
		rows: [],
		totalRows: 0,
		lastQuery: '',
		pageSize: 10,
		pageNumber: 0,
	},
	templateSearch: {
		template_name: '',
		//base_layout: '',
	},

	contentBlocks: {
		rows: [],
		totalRows: 0,
		lastQuery: '',
		pageSize: 10,
		pageNumber: 0,
	},
	contentBlockSearch: {
		string_name: '',
	},


	report: {},
	template: {},
	contentBlock: {},
	autofill: [],
	editorTemplates: [],
	editorContent: '',
	showTemplateReplacementModal: false,
	showNewTemplateModal: false,
	showNewContentBlockModal: false,
	showNewReportModal: false,
};


export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			console.log(action);
			return { ...state, ...action.data };

		case types.RESET_ALL:
			return { ...initialState };

		case types.RESET_PROPERTY:
			return {
				...state,
				[ action.property ]: initialState[ action.property ],
			};

		case types.SET_SUBSTATE:
			return {
				...state,
				[ action.key ]: {
					...state[ action.key ],
					...action.data,
				},
			};


		case types.SET_SEARCH_TERM:
			return {
				...state,
				[ action.searchType ]: {
					...state[ action.searchType ],
					[ action.key ]: action.value,
				},
			};

		case types.UPDATE_REPORT_CONTENT:
			return {
				...state,
				editorContent: action.value,
				report: {
					...state.report,
					urpt_data: {
						...state.report.urpt_data,
						main: action.value,
					},
				},
			};


		default:
			return state;
	}
}

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),



	resetAll: () => ({ type: types.RESET_ALL }),


	resetProperty: (property) => ({ type: types.RESET_PROPERTY, property }),


	setSubState: (key, data) => ({ type: types.SET_SUBSTATE, key, data }),


	setSearchTerm: (searchType, key, value) => ({ type: types.SET_SEARCH_TERM, searchType, key, value }),


	loadReports: (force = false) => async (dispatch, getState) => {
		const data        = getState().reports.reports,
			  loading     = getState().reports.loading,
			  lastQuery   = data.lastQuery,
			  searchTerms = getState().reports.reportSearch,
			  query       = new URLSearchParams({
				  page_size: data.pageSize,
				  page_number: data.pageNumber,
				  ...searchTerms,
			  }),
			  queryString = query.toString();

		if((loading || lastQuery === queryString) && !force) {
			return false;
		}

		dispatch(actions.setState({ loading: true }));
		dispatch(actions.setSubState('reports', { lastQuery: queryString }));

		try {
			let result = await api.get('/reports/list', { params: query });

			dispatch(actions.setSubState('reports', {
				rows: result.data.reports,
				totalRows: result.data.reports.length > 0
					? Number(result.data.reports[ 0 ].total_records)
					: 0,
			}));
			console.log(result.data);
		} catch(err) {

		}

		dispatch(actions.setState({ loading: false }));

	},



	loadTemplates: () => async (dispatch, getState) => {
		const data        = getState().reports.templates,
			  loading     = getState().reports.loading,
			  lastQuery   = data.lastQuery,
			  searchTerms = getState().reports.templateSearch,
			  query       = new URLSearchParams({
				  page_size: data.pageSize,
				  page_number: data.pageNumber,
				  ...searchTerms,
			  }),
			  queryString = query.toString();

		if(loading || lastQuery === queryString) {
			return false;
		}

		dispatch(actions.setState({ loading: true }));
		dispatch(actions.setSubState('templates', { lastQuery: queryString }));

		try {
			let result = await api.get('/reports/templates', { params: query });

			dispatch(actions.setSubState('templates', {
				rows: result.data.templates,
				totalRows: result.data.templates.length > 0
					? Number(result.data.templates[ 0 ].total_records)
					: 0,
			}));
			console.log(result.data);
		} catch(err) {

		}

		dispatch(actions.setState({ loading: false }));
	},



	loadContentBlocks: () => async (dispatch, getState) => {
		const data        = getState().reports.contentBlocks,
			  loading     = getState().reports.loading,
			  stringName  = getState().reports.contentBlockSearch.string_name,
			  lastQuery   = data.lastQuery,
			  query       = new URLSearchParams({
				  page_size: data.pageSize,
				  page_number: data.pageNumber,
				  string_name: stringName,
			  }),
			  queryString = query.toString();

		if(loading || lastQuery === queryString) {
			return false;
		}

		dispatch(actions.setState({ loading: true }));
		dispatch(actions.setSubState('contentBlocks', { lastQuery: queryString }));

		try {
			let result = await api.get('/reports/content-blocks', { params: query });

			dispatch(actions.setSubState('contentBlocks', {
				rows: result.data.content_blocks,
				totalRows: Number(result.data.content_blocks?.[ 0 ]?.total_records || 0),
			}));
			console.log(result.data);
		} catch(err) {

		}

		dispatch(actions.setState({ loading: false }));

	},



	load_report: (report_id, templatesOnly = false) => async (dispatch, getState) => {
		try {
			let result = await api.get(`/reports/load/${report_id}`);

			result.data.report.urpt_data.main = stripScriptTags(result.data.report.urpt_data.main);

			// TODO: MOVE TO BACKEND
			// Replace template strings with real deal
			let templates = result.data.templates,
				content   = result.data.report.urpt_data.main;

			templates.forEach(template => {
				let replaceString = '{$' + template.st_template + '}';
				console.log('replace string', replaceString);
				content = content.replaceAll(replaceString, template.content);
			});

			content = content.replaceAll(/\{\$([^}]+)}/g, '');

			if(templatesOnly) {
				dispatch(actions.setState({
					editorTemplates: templates,
					autofill: result.data.autofill,
				}));
			} else {
				dispatch(actions.setState({
					report: result.data.report,
					editorContent: content,
					editorTemplates: templates,
					autofill: result.data.autofill,
				}));
			}
		} catch(err) {
			console.error(err);
		}
	},



	load_template: (templateID) => async (dispatch, getState) => {
		try {
			let result = await api.get(`/reports/templates/${templateID}`);

			result.data.template.template_config.main = stripScriptTags(result.data.template.template_config.main);

			dispatch(actions.setState({
				template: result.data.template,
				editorContent: result.data.template.template_config.main,
				editorTemplates: result.data.templates,
				autofill: result.data.autofill,
			}));
		} catch(err) {
			console.error(err);
		}
	},



	load_content_block: (contentBlockID) => async (dispatch, getState) => {
		try {
			let result = await api.get(`/reports/content-blocks/${contentBlockID}`);

			result.data.content_block.string_content = stripScriptTags(result.data.content_block.string_content);

			dispatch(actions.setState({
				contentBlock: result.data.content_block,
				editorContent: result.data.content_block.string_content,
				editorTemplates: result.data.templates,
				autofill: result.data.autofill,
			}));
		} catch(err) {
			console.error(err);
		}
	},


	updateReportContent: (value) => ({ type: types.UPDATE_REPORT_CONTENT, value }),


	openTemplateReplacementModal: () => ({ type: types.SET_STATE, data: { showTemplateReplacementModal: true } }),
};
