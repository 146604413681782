import store from 'store';
import drawLineFromOrigin from './drawLineFromOrigin';
import _ from 'lodash';
import { subscribe } from './subscribe';
import { actions } from 'pages/Chart/Chart.redux';



const canvasName = 'occlusal';

export default function(penPal, helper) {
	let hasInitialized = false;

	// SUBLINGUAL VIEW
	subscribe('occlusal_sublingual', {
		data: [ 'occlusalSublingual' ],
		layers: [ 'layers' ],
	}, (current, state, previousState) => {
		renderStaticLayer('sublingual', current.data);
	});


	// SOFT TISSUE
	subscribe('occlusal_soft_tissue', {
		data: [ 'occlusalSoftTissue' ],
		layers: [ 'layers' ],
	}, (current, state, previousState) => {
		renderStaticLayer('soft_tissue', current.data);
	});


	// BONE VIEW
	subscribe('occlusal_bone_view', {
		data: [ 'occlusalBone' ],
		layers: [ 'layers' ],
	}, (current, state, previousState) => {
		renderStaticLayer('bone_view', current.data);
	});


	// TEETH: NORMAL
	subscribe('occlusal_teeth', {
		teeth: [ 'teethOcclusal' ],
	}, (current, full, previous) => {
		renderTeeth('teeth_occlusal', current.teeth);

		setTimeout(() => {
			penPal.sizeCanvas('occlusal', 0);
		}, 500);
	});

	// TEETH: DECIDUOUS
	subscribe('occlusal_teethDeciduous', {
		teethDeciduous: [ 'teethOcclusalDeciduous' ],
	}, (current, full, previous) => {
		renderTeethDeciduous('teeth_occlusal_deciduous', current.teethDeciduous);

		setTimeout(() => {
			penPal.sizeCanvas('occlusal', 0);
		}, 500);
	});


	// MISSING TEETH
	subscribe('occlusal_missingTeeth', {
		teeth: [ 'teethOcclusal' ],
		teethDeciduous: [ 'teethOcclusalDeciduous' ],
		missingTeeth: [ 'missingTeeth' ],
		showMissingAdult: [ 'layers', 'missingTeethAdult' ],
		showMissingDeciduous: [ 'layers', 'missingTeethDeciduous' ],
	}, (current, full, previous) => {
		renderMissingTeeth(
			current.missingTeeth,
			current.teeth,
			current.teethDeciduous,
			{ adult: current.showMissingAdult, deciduous: current.showMissingDeciduous },
		);
	});


	// SYMBOLS
	subscribe('occlusal_symbols', {
		data: [ 'chart_symbols_occlusal' ],
		symbols: [ 'symbols' ],
		teeth: [ 'teeth' ],
		teethLP: [ 'teethLP' ],
		teethDeciduous: [ 'teethDeciduous' ],
		adultTeethVis: [ 'layers', 'adultTeeth' ],
		deciduousTeethVis: [ 'layers', 'deciduousTeeth' ],
		treatmentVis: [ 'layers', 'treatments' ],
		diagnosticVis: [ 'layers', 'diagnostics' ],
		missingTeethAdult: [ 'layers', 'missingTeethAdult' ],
		missingTeethDeciduous: [ 'layers', 'missingTeethDeciduous' ],
		missingTeeth: [ 'missingTeeth' ],
		options: [ 'options' ],
	}, (current, full, previous) => {
		renderSymbols({
			data: current.data,
			options: current.options,
			missingTeeth: current.missingTeeth,
			missingTeethAdult: current.missingTeethAdult,
			missingTeethDeciduous: current.missingTeethDeciduous,
			adultTeethVis: current.adultTeethVis,
			deciduousTeethVis: current.deciduousTeethVis,
			treatmentVis: current.treatmentVis,
			diagnosticVis: current.diagnosticVis,
		});
	});


	// DRAWINGS: OCCLUSAL
	subscribe('occlusal_drawings', {
		teeth: [ 'teeth' ],
		drawings: [ 'drawings_occlusal' ],
	}, (current, full, previous) => {
		try {
			helper.importDrawings(canvasName, current.drawings);
		} catch(err) {
			console.error(err);
		}
	});


	// SHAPES: OCCLUSAL
	subscribe('occlusal_shapes', {
		teeth: [ 'teeth' ],
		shapes: [ 'shapes_occlusal' ],
	}, (current, full, previous) => {
		try {
			helper.importShapes(canvasName, current.shapes);
		} catch(err) {
			console.error(err);
		}
	});


	// TEXT: OCCLUSAL
	subscribe('text_occlusal', {
		text: [ 'text_occlusal' ],
	}, (current, full, previous) => {
		try {
			helper.importText(canvasName, current.text);
		} catch(err) {
			console.error(err);
		}
	});


	// LAYER VISIBILITY
	subscribe('occlusal_layer_visibility', {
		layers: [ 'layers' ],
	}, (current, full, previous) => {
		helper.updateLayerVisibility(canvasName, current.layers);
	});


	// ACTIVE TOOL
	subscribe('occlusal_selected_tool', {
		tool: [ 'toolbar', 'selectedTool' ],
	}, (current, full, previous) => {
		switch(current.tool) {
			case 'markMissing':
				helper.activateTool(canvasName, 'markMissing');
				break;
			case 'drawing':
				helper.activateTool(canvasName, 'drawing');
				break;
			case 'symbolMulti':
			case 'symbolSingle':
				helper.activateTool(canvasName, 'symbols');
				break;
			case 'shapeCircleFilled':
			case 'shapeCircleOutlined':
			case 'shapeRectangleFilled':
			case 'shapeRectangleOutlined':
				helper.activateTool(canvasName, 'shapes');
				break;
			case 'text':
				helper.activateTool(canvasName, 'text');
				break;
			case 'delete':
				helper.activateTool(canvasName, 'delete');
				break;
			case 'move':
				helper.activateTool(canvasName, 'move');
				break;
			case 'rotate':
				helper.activateTool(canvasName, 'rotate');
				break;
			case 'probe':
				helper.activateTool(canvasName, 'probeToothSelection');
				break;
			default:
				helper.activateTool(canvasName, 'dummy');
		}
	});


	// ACTIVE TOOTH
	subscribe('occlusal_active_tooth', {
		activeTooth: [ 'probeOptions', 'activeTooth' ],
		tool: [ 'toolbar', 'selectedTool' ],
	}, (current, state, previousState) => {

		// No need to run this if probing mode is not and has not been active
		if(current.tool !== 'probe' && previousState.toolbar.selectedTool !== 'probe') {
			return true;
		}

		highlightActiveTooth(current.activeTooth, previousState.probeOptions.activeTooth, current.tool, state);
	});


	// POCKET DEPTHS
	subscribe('occlusal_pocket_depths', {
		teeth: [ 'teethOcclusal' ],
		missingTeeth: [ 'missingTeeth' ],
		pocketDepths: [ 'pocketDepths' ],
		vis: [ 'layers', 'probingValues' ],
		activeTooth: [ 'probeOptions', 'activeTooth' ],
		tool: [ 'toolbar', 'selectedTool' ],
		chartConfig: [ 'chartConfig' ],
	}, (current, full, previous) => {
		renderPerioValues(current.pocketDepths, current.chartConfig, full, current.vis);
	});



	function renderTeeth(layerName, teeth) {
		penPal.canvas.select(canvasName);
		penPal.layer.set(layerName);
		let colors = store.getState().chart.colors;

		for(let i = 0; i < teeth.length; i++) {
			let tooth = teeth[ i ];

			let importObj = {
				children: tooth.paths,
				name: tooth.name,
				position: tooth.position,
				...(tooth.options || {}),
				fillColor: colors.defaultFill,
				strokeColor: colors.defaultStroke,
			};

			penPal.path.import(importObj.name, importObj);
		}
	}



	function renderTeethDeciduous(layerName, teeth) {
		penPal.canvas.select(canvasName);
		penPal.layer.set(layerName);
		let colors = store.getState().chart.colors;

		for(let i = 0; i < teeth.length; i++) {
			let tooth = teeth[ i ];

			let importObj = {
				children: tooth.paths,
				name: tooth.name,
				position: tooth.position,
				...(tooth.options || {}),
				fillColor: colors.defaultFillDeciduous,
				strokeColor: colors.defaultStrokeDeciduous,
			};

			penPal.path.import(importObj.name, importObj);
		}
	}



	function renderStaticLayer(layerName, data) {
		let c = helper.setActive(canvasName, layerName);

		let imported;
		if(data.pathType === 'svg') {
			imported = c.layers[ layerName ].layer.importSVG(data.paths);
		} else {
			imported = c.layers[ layerName ].layer.importJSON(data.paths);
		}

		imported.setScaling(data.config.scaling[ 0 ], data.config.scaling[ 1 ]);
		imported.position = data.config.position;
		//imported.opacity = data.softTissueConfig.scaling;
		imported.opacity = data.config.opacity ?? 1;
	}



	function renderPerioValues(pocketDepths, chartConfig, state, vis) {
		if(!vis) {
			return false;
		}

		if(!Array.isArray(pocketDepths)) {
			return false;
		}


		let layerName  = 'perio_occlusal',
			toothLayer = 'teeth_occlusal',
			c          = penPal.canvas.select(canvasName);

		let colors = [
			'#413C58', '#000000', '#C81D25', '#05668D', '#322F20', '#00A896',
		];

		const abnormalValue = state.options.abnormalPocketDepth || 0;

		penPal.layer.set(layerName);
		// Remove the layer and remake it
		c.layers[ layerName ].layer.remove();
		delete (c.layers[ layerName ]);
		penPal.layer.set(layerName);

		for(let i = 0; i < pocketDepths.length; i++) {
			let row       = pocketDepths[ i ],
				toothName = row.name,
				tooth     = c.layers[ toothLayer ].paths[ toothName ];

			if(!tooth) {
				continue;
			}

			renderValues(chartConfig, toothName, row.values);
		}

		function renderValues(chartConfig, toothName, values) {
			let confSrc = chartConfig[ toothName ],
				rotate  = confSrc?.probeValueRotation ?? 180;

			let tooth       = c.layers[ toothLayer ].paths[ toothName ],
				toothBounds = tooth.bounds,
				radius      = toothBounds.size.height > toothBounds.size.width
					? toothBounds.size.height
					: toothBounds.size.width;

			radius = radius / 2 + 12;
			let pockets     = state.chartConfig[ toothName ].pockets,
				center      = [ toothBounds.center.x, toothBounds.center.y ],
				activeTooth = state.probeOptions.activeTooth || '';


			let mapFlipX = [],
				mapFlipY = [];

			mapFlipX[ 4 ] = [ 2, 1, 0, 3 ];
			mapFlipX[ 6 ] = [ 3, 2, 1, 0, 5, 4 ];

			mapFlipY[ 4 ] = [ 0, 3, 2, 1 ];
			mapFlipY[ 6 ] = [ 0, 5, 4, 3, 2, 1 ];

			for(let i = 0; i < pockets; i++) {
				if(values[ i ] && values[ i ] < abnormalValue) {
					continue;
				}

				let newVal = i;

				if(confSrc.probeFlipHorizontal) {
					newVal = mapFlipX[ pockets ][ i ];
				}
				if(confSrc.probeFlipVertical) {
					newVal = mapFlipY[ pockets ][ i ];
				}

				let newRadius = toothName === activeTooth
					? radius + 8
					: radius;

				let x = center[ 0 ] + newRadius * Math.cos((2 * Math.PI * newVal / pockets) + (rotate * (Math.PI / 180))),
					y = center[ 1 ] + newRadius * Math.sin((2 * Math.PI * newVal / pockets) + (rotate * (Math.PI / 180)));

				let text = new c.paper.PointText(new c.paper.Point(0, 0));
				text.justification = 'center';
				text.fillColor = colors[ i ];
				text.content = values[ i ] || '';
				text.fontSize = (toothName === activeTooth && state.toolbar.selectedTool === 'probe')
					? 40
					: 25;
				text.position = [ x, y ];
				text.opacity = 1;
				text.fontWeight = toothName === activeTooth
					? 'bold'
					: 'normal';
			}
		}
	}



	function renderSymbols({
		data,
		options,
		missingTeeth,
		missingTeethAdult,
		missingTeethDeciduous,
		adultTeethVis,
		deciduousTeethVis,
		treatmentVis,
		diagnosticVis,
	}) {
		penPal.canvas.select(canvasName);

		// Diagnostics
		if(diagnosticVis) {
			helper.clearLayer(canvasName, 'diagnostics');
			helper.clearLayer(canvasName, 'diagnosticLines');
			penPal.layer.set('diagnostics');
			let dx = data
				.filter(row => row.symbol_type === 'dx')
				.filter(row => {
					if(!row.tooth) {
						return true;
					}

					let toothType      = row.tooth.substring(0, 1).toLowerCase(),
						toothIsMissing = missingTeeth.includes(row.tooth);

					if(toothIsMissing && toothType === 'd' && !missingTeethDeciduous) {
						return false;
					}

					if(toothIsMissing && toothType === 't' && !missingTeethAdult) {
						return false;
					}

					if(toothType === 'd') {
						return deciduousTeethVis;
					}

					return adultTeethVis;
				});

			dx.forEach(row => {
				try {
					renderDiagDefault(row, options);
				} catch(err) {
					console.warn('Diagnostic rendering error:');
					console.warn(err);
				}
			});
		}


		// Treatments
		if(treatmentVis) {
			helper.clearLayer(canvasName, 'treatments');
			helper.clearLayer(canvasName, 'treatmentLines');
			penPal.layer.set('treatments');
			let tx = data
				.filter(row => row.symbol_type === 'tx')
				.filter(row => {
					if(!row.tooth) {
						return true;
					}

					let toothType      = row.tooth.substring(0, 1).toLowerCase(),
						toothIsMissing = missingTeeth.includes(row.tooth);

					if(toothIsMissing && toothType === 'd' && !missingTeethDeciduous) {
						return false;
					}

					if(toothIsMissing && toothType === 't' && !missingTeethAdult) {
						return false;
					}

					if(toothType === 'd') {
						return deciduousTeethVis;
					}

					return adultTeethVis;
				});

			tx.forEach(row => {
				try {
					renderTreatmentDefault(row, options);
				} catch(err) {
					console.warn('Treatment rendering error:');
					console.warn(err);
				}
			});
		}

	}



	function renderDiagDefault(row, options) {
		let lineOpts = {
			strokeColor: options.dxColorAdult,
			strokeWidth: options.codeFontSize / 16,
			toothLayer: null,
		};

		let c     = helper.setActive(canvasName, 'diagnostics'),
			color = (row.tooth && row.tooth.substring(0, 1) === 'd')
				? options.dxColorDeciduous
				: options.dxColorAdult;

		let textConfig = {
			point: row.position,
			justification: 'center',
			content: color === options.dxColorAdult
				? row.render_value
				: row.render_value.toLowerCase(),
			name: row.name,
			fullySelected: false,
			fontSize: options.codeFontSize || 48,
			fontWeight: 'bold',
			fillColor: color,
		};

		/*if(row.actualLayer) {
			lineOpts.toothLayer = row.actualLayer;
		}*/

		const text     = new c.paper.PointText(textConfig),
			  { x, y } = helper.calculateOutOfBoundsSymbolPosition({
				  canvasName, paperItem: text,
			  });

		text.point = new c.paper.Point([ x, y ]);

		try {
			drawLineFromOrigin(helper, text, row, 'diagnosticLines', { ...lineOpts, strokeColor: color });
		} catch(err) {
			console.error(err);
		}

		helper.setActive(canvasName, 'diagnostics');

		// TODO: Reduce symbol opacity to 30% by default, 15% in fade mode
		//text.opacity = 0.15;

		return text;
	}



	function renderTreatmentDefault(row, options) {
		let lineOpts = {
			strokeColor: options.txColorAdult,
			strokeWidth: options.codeFontSize / 16,
			toothLayer: null,
		};

		let c     = helper.setActive(canvasName, 'treatments'),
			color = (row.tooth && row.tooth.substring(0, 1) === 'd')
				? options.txColorDeciduous
				: options.txColorAdult;

		let textConfig = {
			point: row.position,
			justification: 'center',
			content: color === options.txColorAdult
				? row.render_value
				: row.render_value.toLowerCase(),
			name: row.name,
			fullySelected: false,
			fontSize: options.codeFontSize || 48,
			fontWeight: 'bold',
			fillColor: color,
		};

		/*if(row.actualLayer) {
			lineOpts.toothLayer = row.actualLayer;
		}*/

		const text     = new c.paper.PointText(textConfig),
			  { x, y } = helper.calculateOutOfBoundsSymbolPosition({
				  canvasName, paperItem: text,
			  });

		text.point = new c.paper.Point([ x, y ]);

		try {
			drawLineFromOrigin(helper, text, {
				...row,
				//actualLayer: row.actualLayer.replace('lingualPalatal', 'lingual_palatal'), // TODO: Remove this .replace
			}, 'treatmentLines', { ...lineOpts, strokeColor: color });
		} catch(err) {
			console.error(err);
		}

		helper.setActive(canvasName, 'treatments');

		// TODO: Reduce symbol opacity to 30% by default, 15% in fade mode
		//text.opacity = 0.15;

		return text;
	}



	function renderMissingTeeth(missing, teethSource, teethDeciduous, showMissing) {
		let c = penPal.canvas.select(canvasName);
		let teeth = [
			...teethSource.map(tooth => tooth.name),
			...teethDeciduous.map(tooth => tooth.name),
		];
		let colors = store.getState().chart.colors;

		update('teeth_occlusal');
		update('teeth_occlusal_deciduous', true);

		function update(toothLayer, isDeciduous = false) {
			for(let i = 0; i < teeth.length; i++) {
				try {
					let toothName = teeth[ i ],
						tooth     = c.layers[ toothLayer ].paths[ toothName ],
						index     = missing.indexOf(toothName),
						opacity   = 1;

					if(!showMissing[ isDeciduous
						? 'deciduous'
						: 'adult' ]) {
						opacity = 0;
					}

					if(index === -1) {
						_.assign(tooth, {
							opacity: 1,
							fillColor: isDeciduous
								? colors.defaultFillDeciduous
								: colors.defaultFill,
							strokeColor: isDeciduous
								? colors.defaultStrokeDeciduous
								: colors.defaultStroke,
						});
					} else {
						_.assign(tooth, {
							opacity: opacity,
							fillColor: isDeciduous
								? colors.defaultFillLightDeciduous
								: colors.defaultFillLight,
							strokeColor: isDeciduous
								? colors.defaultStrokeLightDeciduous
								: colors.defaultStrokeLight,
						});
					}
				} catch(err) {
					console.log(i, teeth[ i ]);
					console.log(toothLayer, c.layers);
					console.log(err);
				}
			}
		}

		return true;
	}



	function markMissing(e) {
		try {
			if(!e.item) {
				return false;
			}

			let layers      = store.getState().chart.layers,
				toothLayers = [ 'teeth_occlusal', 'teeth_occlusal_deciduous' ],
				layerVisMap = {
					"teeth_occlusal": "adultTeeth",
					"teeth_occlusal_deciduous": "deciduousTeeth",
				},
				c           = penPal.canvas.select(canvasName),
				layerName   = e.item.layer.name,
				layerVis    = layers[ layerVisMap[ layerName ] ];

			if(!e.item
				|| !e.item.name
				|| !toothLayers.includes(layerName)
				|| !c.layers[ layerName ].paths.hasOwnProperty(e.item.name)
				|| !layerVis) {
				return false;
			}

			let tooth = e.item.name;
			store.dispatch(actions.toggleMissing(tooth));
		} catch(err) {
			console.error(err);
		}
	}


	function probeToothSelection(e) {
		try {
			if(!e.item) {
				return false;
			}

			let layers      = store.getState().chart.layers,
				toothLayers = [ 'teeth_occlusal' ],
				layerVisMap = {
					"teeth_occlusal": "adultTeeth",
				},
				c           = penPal.canvas.select(canvasName),
				layerName   = e.item.layer.name,
				layerVis    = layers[ layerVisMap[ layerName ] ];

			if(!e.item
				|| !e.item.name
				|| !toothLayers.includes(layerName)
				|| !c.layers[ layerName ].paths.hasOwnProperty(e.item.name)
				|| !layerVis) {
				return false;
			}

			let tooth = e.item.name;
			store.dispatch(actions.setProbingOptions({
				activePocket: 0,
				activeTooth: tooth,
			}));
		} catch(err) {
			console.error(err);
		}
	}



	function highlightActiveTooth(activeTooth, previousTooth, activeTool, state) {
		let colors = state.colors;
		let shouldHighlight = activeTool === 'probe';
		/*if((tooth === last.tooth || !hasInitialized) && !shouldHighlight) {
		 return false;
		 }*/

		let c = penPal.canvas.select(canvasName);

		fill(
			c.layers[ 'teeth_occlusal' ].paths[ previousTooth || activeTooth ],
			c.layers[ 'teeth_occlusal' ].paths[ activeTooth ],
		);

		function fill(previous, current) {
			console.log(previous);
			console.log(current);
			previous.strokeColor = colors.defaultStroke;
			previous.fillColor = colors.defaultFill;
			previous.strokeWidth = 3;
			current.strokeColor = shouldHighlight
				? colors.selectStroke
				: colors.defaultStroke;
			current.fillColor = shouldHighlight
				? colors.selectFill
				: colors.defaultFill;
			current.strokeWidth = shouldHighlight
				? 7
				: 3;
		}
	}



	this.init = function(target) {

		//store.dispatch(actions.resetChart({ Species: 'canine' }));
		//store.dispatch(actions.resetChartingOptions());
		helper.addCanvas(canvasName, target, 1.1, 2.2);
		hasInitialized = true;
		penPal.canvas.select('occlusal');

		// Tools
		helper.addDummyTool(canvasName);
		helper.addClickTool(canvasName, 'markMissing', markMissing);
		helper.addClickTool(canvasName, 'probeToothSelection', probeToothSelection);
		helper.addDrawingTool(canvasName, helper.saveDrawings);
		helper.addShapeTool(canvasName, helper.saveShapes);
		helper.addDeleteTool(canvasName);
		helper.addMoveTool(canvasName);
		helper.addRotateTool(canvasName);
		helper.addSymbolTool(canvasName);
		helper.addTextTool(canvasName);

		helper.activateTool(canvasName, 'dummy');
	};



	this.destroy = function() {

		if(!penPal.canvas.exists(canvasName)) {
			return false;
		}

		let c = penPal.canvas.select(canvasName);

		c.paper.view.onResize = null;
		penPal.canvas.destroy(canvasName);
	};


}
