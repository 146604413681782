import React, { useCallback, useRef, useState } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { useDispatch } from "react-redux";
import { Button, Menu, MenuItem } from '@mui/material';
import { actions } from 'pages/PatientView/PatientView.redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import DeletePopover from 'components/DeletePopover';



const PatientChartsCell = (props) => {
	const id                              = props.row.visit_id,
		  dispatch                        = useDispatch(),
		  { patient_id }                  = useParams(),
		  actionAnchor                    = useRef(null),
		  [ actionsOpen, setActionsOpen ] = useState(false);

	const { column } = props;


	const handleCopyChart = useCallback(() => {
		setActionsOpen(false);
		dispatch(actions.addNewChart(patient_id, id));
	}, [ dispatch, patient_id, id, setActionsOpen ]);



	const beforeDeleteChart = useCallback(() => {
		dispatch(actions.setState({
			chartsLoading: true,
		}));
	}, [ dispatch ]);

	const afterDeleteChart = useCallback(() => {
		dispatch(actions.loadCharts(patient_id));
		setActionsOpen(false);
	}, [ dispatch ]);


	const handleNavigate = useCallback(() => {
		dispatch(push(`/chart/${id}`));
	}, [ dispatch, id ]);



	if(column.name === 'actions') {
		return (
			<Table.Cell
				{...props}
			>
				<Button
					variant="outlined"
					size="large"
					onClick={() => setActionsOpen(true)}
					ref={actionAnchor}
				>
					...
				</Button>
				<Menu

					open={actionsOpen}
					onClose={() => setActionsOpen(false)}
					anchorEl={actionAnchor.current}
				>
					<MenuItem>
						<Button variant="text" size="large" onClick={handleCopyChart}>
							Copy Chart
						</Button>
					</MenuItem>
					<MenuItem>
						<DeletePopover
							objectType="CHART"
							objectID={id}
							afterDelete={afterDeleteChart}
							beforeDelete={beforeDeleteChart}
						>
							<Button variant="text" size="large" color="error">
								Delete Chart
							</Button>
						</DeletePopover>
					</MenuItem>
				</Menu>

			</Table.Cell>
		);
	}

	return (
		<Table.Cell
			{...props}
			onClick={handleNavigate}
		/>
	);
};


export default PatientChartsCell;
